@import "core/index.scss";
.page.checkout.checkout-summary {
	.summary-message {
		background-color: $color-pink;
		color: $color-white;
		padding: 4rem 3.6rem;
		margin-bottom: 4rem;
		border-radius: $radius-general;

		@include media {
			padding: 2rem 2rem;
		}
	}

		.message-title {
			@include font-bold;
			font-size: 5rem;
			line-height: 1.3em;

			@include media {
				font-size: 2.4rem;
			}
		}

		.message-links {
			margin-top: 2rem;

			@include mediaMin {
				> * + * {
					margin-left: 1rem;
				}
			}
		}

		.links-link {
			@include media {
				width: 100%;

				+ * {
					margin-top: 1.5rem;
				}
			}
		}

	.summary-products {
		.products-list {
			border: .1rem solid $color-gray-light;
			padding: 0 2rem;
			border-radius: $radius-general;
			box-shadow: $shadow-input;
		}

			.list-product {
				padding: 2rem 0;

				+ .list-product {
					border-top: .1rem solid $color-gray-light;
				}
			}
	}

	.summary-info {

		.info-content {
			padding: 2.5rem 2rem;
		
			@include mediaMin {
				border-radius: $radius-general;
				box-shadow: $shadow-input;
				border: .1rem solid $color-gray-light;
			}

			@include media {
				border-top: .1rem solid $color-gray-light;
				border-bottom: .1rem solid $color-gray-light;
				width: calc(100% + 4rem);
				margin: 0 -2rem;
			}
		}
		
			.content-data {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
			}

				.data-section {
					width: calc(50% - 1rem);
					display: flex;
					color: $color-text-light;
					padding: .5rem 0;

					@include media {
						display: block;
					}

					strong {
						// width: 30%;
						color: $color-text;

						@include media {
							display: block;
						}
					}

					> span {
						flex: 1 0 0;
						margin-left: 1rem;

						@include media {
							flex: none;
							display: block;
							margin: 1rem 0 0;
						}
					}
				}

			.content-bottom {
				background-color: $color-bg-light;
				border-radius: $radius-general;
				margin-top: 2rem;
				padding: 2rem;
				display: flex;
				justify-content: space-between;	
				
				@include media {
					display: block;
				}

				.bottom-section {
					display: flex;
					flex-direction: column;
					
					@include mediaMin {
						width: calc(50% - 1rem);
					}

					@include media {
						+ .bottom-section {
							margin-top: 2rem;
						}
					}
				}

					.section-title {
						color: $color-text-light;
						font-size: 2rem;
						display: block;
						margin-bottom: 2rem;
					}

					.section-content {
						flex: 1 0 0;
						display: flex;
						justify-content: center;
						flex-direction: column;

						&.white {
							background-color: $color-white;
							border-radius: $radius-general;
							padding: 2rem;
						}
					}

				// .bottom-addresses {
				// }

					.addresses-address {
						+ .addresses-address {
							margin-top: 3rem;
						}
					}

						.address-title {
							display: block;
							margin-bottom: .5rem;
						}

						.address-info {
							color: $color-text-light;
							line-height: 1.3em;
							font-size: 1.4rem;
						}

						.address-contact {
							@include font-medium;
							color: $color-text-lighter;
							margin-top: .5rem;
						}

				// .bottom-payment {}

					.payment-title {
						color: $color-text-lighter;
						margin-bottom: .4rem;
						display: block;
					}

					.payment-services {
						margin-top: 3rem;
					}

						.services-service {
							display: flex;
							justify-content: space-between;
							color: $color-text-light;

							+ .services-service {
								margin-top: .6rem;
							}

							strong {
								color: $color-text-lighter;
							}
						}

					.payment-total {
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-top: .1rem solid $color-gray-light;
						padding-top: 2rem;
						margin-top: 3rem;

						strong {
							font-size: 1.8rem;
						}

						.pricebox {
							font-size: 2rem;
						}
					}

					.payment-info {
						@include font-semibold;
						margin-top: .5rem;
						display: flex;
						justify-content: flex-start;
						align-items: center;

						&.account {
							.info-detail {
								@include font-medium;
								max-width: 30%;
								color: $color-text-lighter;
								font-size: 1.4rem;
							}
						}
					}

						.info-image {
							width: 6rem;
							height: 3rem;
							border: .2rem solid $color-gray-light;
							border-radius: $radius-general;
						}

						.info-data {
							margin-left: 2rem;
							flex: 1 0 0;
						}

						.info-detail {
							margin-left: 2rem;
							text-align: right;
						}
			}

			.content-agreements {
				@include font-medium;
				padding: 2rem 0 0;
				font-size: 1.8rem;
				color: $color-text-light;

				> * + * {
					margin-left: 2rem;
				}
			}
	}
}